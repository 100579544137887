import React, { useState, useRef } from "react"
import PropTypes from 'prop-types'
import { graphql, Link } from "gatsby"
// ---------------------------------------------
import ExDisplayPrices from "../components/exDisplayPrices"
import Enquire from "../components/exDisplayEnquire"
import Gallery from "../components/gallery"
// import Hyperlink from "../components/hyperlink"
import Layout from "../components/layout"
import Markdown from "../components/markdown"
import Revealer from "../components/revealer"
import Materials from "../components/productMaterials"
// import ScrollToLink from "../components/scrollto"
// import Sections from "../components/sections"
// import Swatches from "../components/swatches"
import SEO from "../components/seo"
// ---------------------------------------------



const ExDisplay = ({ data }) => {

	const {
		title,
    productName,
		materialcolour,
		soldOut,
		originalPrice,
		discountPrice,
		thumbnail,
		landscapeGallery,
		gallery,
		product: {
			seoDescription,
			slug,
			description,
			dimensions,
			frame
		},
		details,
		information
	} = data.contentfulExDisplay

  // ------------------
  // Letting the <Revealer /> components know when the page is scrolling

  // const scrollTarget = useRef();
  // const [isScrolling, setScrolling] = useState(false)

  // const onScrollStart = () => {
  //   setScrolling(true)
  // }

  // ------------------
  // Markup...

  return (
    <Layout>
      <SEO title={title} description={seoDescription} shareImage={thumbnail} />

      {/*
        -------------------------------------
        PRODUCT SHOTS GALLERY & PRODUCT INFO
        -------------------------------------
      */}
			<Revealer force={landscapeGallery ? true : false}>
        <section className="gutters outer">
          <div className="product-hero">
            <div className="product-hero__gallery span-6 pad">
              <div className="gallery-wrapper">
                {gallery && (
                  <Gallery className="fs-gallery" pictureClassName="fs cover" images={gallery} />
                )}
              </div>
            </div>
            <div className="span-6 pad">
              <div className="max32">
                <div className="p3 mbx2">
                  <h3>{productName || title} (Ex-display)</h3>
									<p>in {materialcolour}</p>
                  <ExDisplayPrices 
										originalPrice={originalPrice} 
										discountPrice={discountPrice} 
									/>
									{soldOut && <p>Sold</p>}
                </div>
								
                <Markdown className="md p3 has-links" field={description} />
                <Markdown className="md p3" field={details} />

								{slug && (
									<p className="outerx2">
										<Link to={`/${slug}`} className="link">More about this product</Link>
									</p>
								)}

                <div className="outerx2">
                  {!soldOut && <Enquire title={title} materialcolour={materialcolour} />}
                </div>

								{information && (
									<Markdown className="md" field={information} />
								)}

                {!information && dimensions && (
                  <>
                    <h5>Dimensions</h5>
                    <Markdown className="md" field={dimensions} />
                  </>
                )}

								{!information && (frame) && (
                  <Materials frame={frame} hideMessage />
                )}

								<p className="mtx4">
									
									<Link to="/ex-display" className="link"><em>{`< Back to all Ex-display`}</em></Link>
								</p>
              </div>
            </div>
          </div>
        </section>
      </Revealer>

			{/*
        -------------------------------------
        LANDSCAPE GALLERY (IF NEEDED)
        -------------------------------------
      */}
			{landscapeGallery && (
				<Revealer>
					<section className="gutters outer">
						<div className="gallery-wrapper-landscape pad">
							{gallery && (
								<Gallery className="fs-gallery" pictureClassName="fs cover" images={landscapeGallery} />
							)}
						</div>
					</section>
				</Revealer>
			)}

    </Layout>
  )
}

ExDisplay.propTypes = {
  data: PropTypes.object.isRequired
}

export default ExDisplay

/*
  -------------------------------------
  GRAPHQL
  -------------------------------------
*/
export const pageQuery = graphql`

	

  query ExDisplayQuery($slug: String!, $createdAt: Date!){
    contentfulExDisplay(slug: {eq: $slug}, createdAt: {eq: $createdAt}) {
      ...ExDisplayLink
			landscapeGallery {
				id
				fluid(maxWidth: 2400) {
					...GatsbyContentfulFluid_withWebp_noBase64
				}
			}
			gallery {
				id
				fluid(maxWidth: 1600) {
					...GatsbyContentfulFluid_withWebp_noBase64
				}
			}
			details {
				childMarkdownRemark {
					html
				}
			}
			information {
				childMarkdownRemark {
					html
				}
			}
			product {
				seoDescription
				slug
				description {
					childMarkdownRemark {
						html
					}
				}
				dimensions {
					childMarkdownRemark {
						html
					}
				}
				frame {
					childMarkdownRemark {
						html
					}
				}
			}
    }
  }
`
