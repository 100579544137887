// Dependencies
import React from 'react'

export const formatPrice = (value, currency = 'GBP') =>
 Intl.NumberFormat("en-GB", {
	 currency,
	 minimumFractionDigits: 2,
	 maximumFractionDigits: 2,
	 style: "currency",
 }).format(value)

const ExDisplayPrices = ({ originalPrice, discountPrice }) => {
	
	const originalPriceFormat = formatPrice(originalPrice)
	const discountPriceFormat = formatPrice(discountPrice)

	const discountPercentage = Math.round(100 - ((discountPrice / originalPrice) * 100))

	return (
		<>
			<p>
				<del>{originalPriceFormat}</del>
				{(discountPrice && discountPercentage) ? <em className="discount-percentage">{discountPercentage}% discount</em> : null}
			</p>
			<p>
				<span>{" "}{discountPriceFormat}</span>
			</p>
		</>
	)
}

export default ExDisplayPrices
