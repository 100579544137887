// Dependencies
import React from 'react'

const Enquire = ({ className, title, materialcolour }) => (
  <a 
		href={`mailto:orders@sedilia.com?subject=Ex-Display Enquiry&body=${title}, ${materialcolour}`} 
		className={className || "btn outerx2"} 
		target="_blank" 
		rel="noopener noreferrer"
	>
    Enquire
  </a>
)

export default Enquire